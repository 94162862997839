import * as Models from "static/js/app/models/__index";
import StringFormatting from "static/js/app/utils/stringFormatting/stringFormatting";
export class evolution_finance_calculator
{

    public static init(evolutionUsername: string, config: Models.FinanceSearchTerms , vehicleSingle: Models.Vehicle, pluginContentDivId: string = "financePluginContent" )
    {
        this.initEvolutionFinanceScript(evolutionUsername, config, vehicleSingle,pluginContentDivId);
    }
private static initEvolutionFinanceScript(evolutionUsername: string, config: Models.FinanceSearchTerms , vehicleSingle: Models.Vehicle, pluginContentDivId: string)
    {
        if (vehicleSingle == null || vehicleSingle.sold ==true)
        { 
            return;
        }
        let evolutionEl = document.querySelector(`#${pluginContentDivId}`);
        if (evolutionEl ==null)
        {
            return;
        }
        let evolutionRequest = this.MapEvolutionRequent(evolutionUsername, vehicleSingle, config, pluginContentDivId);
        
        let evolutionScriptTag = document.createElement("script");
        evolutionScriptTag.async = true;
        evolutionScriptTag.type = "text/javascript";     
        evolutionScriptTag.src = evolution_finance_calculator.getPluginPath(evolutionRequest);
        evolutionEl.appendChild(evolutionScriptTag);
    }
    private static getPluginPath(evolutionRequest:evolutionRequest): string
    {
        return `https://financeproposal.co.uk/widget2/widget.js?${$.param(evolutionRequest)}`;        
    }
   

    private static MapEvolutionRequent(evolutionUsername: string, vehicle: Models.Vehicle, config: Models.FinanceSearchTerms, pluginContentDivId: string): evolutionRequest
    {
        let vehicleClass = this.mapVehicleType(vehicle.vehicle_type);
        let deposit = (config.percentage_deposit > 0 ? (config.percentage_deposit / 100) * vehicle.price : config.deposit_amount  );
        
        return new evolutionRequest(
            evolutionUsername,                        
            vehicle.vrm ?? 'x',
            vehicle.cap_id,
            StringFormatting.dateToString(vehicle.registration_date),
            vehicle.price,
            vehicleClass,
            vehicle.mileage,
            vehicle.manufacturer_display,
            vehicle.model_display,
            vehicle.derivative_display,
            (vehicle.images.length > 0 ? `https://${window.location.host}/${vehicle.images[0].i850x638}` : ""),
            deposit,
            pluginContentDivId)        
                              
    }

    private static mapVehicleType(vehicleType: string) : number
    {
        if (vehicleType == null)
        { 
            return 1;
        }
        switch(vehicleType?.toLowerCase())
        {
            case "car":
                return 1;
            case "van":
            case "commercial":
                return 2;
            case "caravan":
            case "motorhome":
                return 3;
            case "bike":
                return 5;
            default:
                return 1;
        }
    }


}

    class evolutionRequest
    {       
        constructor(
        public ice_id: string,       
        public vrm: string,
        public cap_id: string,
        public registration_date: string,
        public price: number,
        public vehicle_type: number,
        public mileage: number,
        public make: string,
        public model :string,
        public deriv :string,
        public image: string,
        public deposit: number,
        public placeholder: string
       
        
        ) {}
    }